import { createGlobalStyle } from "styled-components";
import { device } from './breakpoints';

const Common = createGlobalStyle`

*{
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}


//Fonts Size

h1 {
    font-size: 40px;
}
h2 {
    font-size: 24px;
}
p {
    font-size: 14px;
}

label{
    font-size: 14px;
}

input{
    font-family: 'Rubik';
}
button{
    font-size:16px;
    font-weight:400;
}

/*--- Margin and Padding ---*/

//padding
.p-0{
    padding: 0;
}
.pr-0{
    padding-right: 0;
}
.pl-0{
    padding-left: 0;
}
.pt-0{
    padding-right: 0;
}
.pb-0{
    padding-left: 0;
}

// margin
.m-0{
    padding: 0;
}
.mr-0{
    padding-right: 0;
}
.ml-0{
    padding-left: 0;
}
.mt-0{
    padding-right: 0;
}
.mb-0{
    padding-left: 0;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.text-success {
    color: --success--color;
}

.text-danger {
    color: --danger--color;
    margin-top: 5px;
    line-height: 1;
}

.text-warning {
    color: --warning--color;
}

.text-info {
    color: --info--color;
}
.mt-30{
    margin-top: 30px;
}
.mb-30{
    margin-bottom: 10px;
}
// Width css
.w-100 {
    width: 100%;
}

// Height SCSS
.min-h100 {
    min-height: 100vh;
}

.h-100 {
    height: 100%;
}

.container{
    width: 1170px;
    max-width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    margin: 0 auto;
} 

.container-fluid {
    width: 100%;
    max-width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    margin: 0 auto;
}


.form-group{
    margin-bottom: 30px;
    .form-label{
        font-weight: 300;
        font-size: 17px;
        line-height: 142%;
        color: #3B3D46;
        display: block;
        margin-bottom:10px;
        text-transform: capitalize;
    }
    .form-control{
        background-color:#fff;
        height: 48px;
        width: 100%;
        padding: 6px 12px;
        border: 0.5px solid #B1B1B1;
        border-radius: 12px;
        color: #3B3D46;
        font-size:16px;
        font-weight:300;
        font-family: 'Poppins', sans-serif;
        &:focus{
            border: 0.5px solid var(--btn--color);
        }
    }
}

.shadow-paper{
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(40px);
    -webkit-backdrop-filter: blur(40px);
    -ms-backdrop-filter: blur(40px);
    -moz-backdrop-filter: blur(40px);
    border-radius: 24px;
    padding:24px;
    max-width:750px;
    margin: auto;
    width: 100%;
    &.no-shadow{
        background:none;
        backdrop-filter: none;
        padding:12px;
    }
}

@media only screen and (${device.sm}){
    .mt-30{
        margin-top: 0;
    }
}


`;

export default Common