export const API = 'API'
export const API_REQUEST = 'API_REQUEST'
export const API_SUCCESS = 'API_SUCCESS'
export const API_ERROR = 'API_ERROR'

export const LOGIN_S = 'LOGIN_SUCCESS'
export const LOGIN_F = 'LOGIN_FAIL'

export const LOGOUT = 'LOGOUT'

export const RESET_PASSWORD_S = "RESET_SUCCESS"
export const RESET_PASSWORD_F = "RESET_FAIL"

export const RESET_PASSWORD_FS = "RESET_F_SUCCESS"
export const RESET_PASSWORD_FF = "RESET_F_FAIL"

export const CHANGE_PASSWORD_S = "CHANGE_SUCCESS"
export const CHANGE_PASSWORD_F = "CHANGE_FAIL"

export const FORGOT_PASSWORD_S = "FORGOT_SUCCESS"
export const FORGOT_PASSWORD_F = "FORGOT_FAIL"

export const FARMERMARKET_PROFILE_S = "PROFILE_SUCCESS"
export const FARMERMARKET_PROFILE_F = "PROFILE_FAIL"

export const GET_STATE_S = "STATE_SUCCESS"
export const GET_STATE_F = "STATE_FAIL"

export const GET_COUNTIES_S = "COUNTIES_SUCCESS"
export const GET_COUNTIES_F = "COUNTIES_FAIL"

export const GET_FARMERLIST_S = "FARMERLIST_SUCCESS"
export const GET_FARMERLIST_F = "FARMERLIST_FAIL"

export const ASSOCIATED_S = "ASSOCIATED_SUCCESS"
export const ASSOCIATED_F = "ASSOCIATED_FAIL"

export const DEASSOCIATED_S = "DEASSOCIATED_SUCCESS"
export const DEASSOCIATED_F = "DEASSOCIATED_FAIL"

export const SUSPEND_S = "SUSPEND_SUCCESS"
export const SUSPEND_F = "SUSPEND_FAIL"

export const REQUEST_S = "REQUEST_SUCCESS"
export const REQUEST_F = "REQUEST_FAIL"

export const ADD_FARMERMARKET_S = "FARMERMARKET_SUCCESS"
export const ADD_FARMERMARKET_F = "FARMERMARKET_FAIL"

export const ADD_EVENT_S = "ADD_EVENT_S"
export const ADD_EVENT_F = "ADD_EVENT_S"

export const GET_EVENT_S = "GET_EVENT_S"
export const GET_EVENT_F = "GET_EVENT_S"

export const ADD_EVENT_STATUS_S = "ADD_EVENT_STATUS_S"
export const ADD_EVENT_STATUS_F = "ADD_EVENT_STATUS_F"

export const UPDATE_EVENT_S = "UPDATE_EVENT_S"
export const UPDATE_EVENT_F = "UPDATE_EVENT_F"

