import { createGlobalStyle } from "styled-components";
import { toAbsoluteUrl } from '../utils';

const Theme = createGlobalStyle`

    .theme-wrap{
        padding: 50px 0;
        margin-top: 90px;
    }
    /* animation btn */
    .btn-primary{
        background-color:var(--btn--color);
        border-radius: 12px;
        font-weight: 400;
        font-size: 16px;
        line-height: 140%;
        color:var(--white--color);
        height: 48px;
        padding: 10px 20px;
        cursor: pointer;
        -webkit-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        &:hover{
            background-color:var(--btn--color);
        }
    }

    .btn-disable{
        background-color: #673B94;
        border-radius: 12px;
        font-weight: 400;
        font-size: 16px;
        line-height: 140%;
        color:var(--white--color);
        height: 48px;
        padding: 10px 20px;
        cursor: pointer;
        -webkit-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        &:hover,&.active{
            background-color:var(--btn--color);
        }
    }
    .btn-danger{
        background-color:var(--danger--color);
        border-radius: 12px;
        font-weight: 400;
        font-size: 16px;
        line-height: 140%;
        color:var(--white--color);
        height: 48px;
        padding: 10px 20px;
        cursor: pointer;
        -webkit-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        &:hover,&.active{
            background-color:var(--danger--color);
        }
    }

    .btn-boxshadow{
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08), inset 0px 0px 4px rgba(255, 255, 255, 0.2), inset 0px 10px 16px rgba(255, 255, 255, 0.32), inset 0px -10px 12px rgba(0, 0, 0, 0.24);
        -ms-box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08), inset 0px 0px 4px rgba(255, 255, 255, 0.2), inset 0px 10px 16px rgba(255, 255, 255, 0.32), inset 0px -10px 12px rgba(0, 0, 0, 0.24);
        -moz-box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08), inset 0px 0px 4px rgba(255, 255, 255, 0.2), inset 0px 10px 16px rgba(255, 255, 255, 0.32), inset 0px -10px 12px rgba(0, 0, 0, 0.24);
        -webkit-box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08), inset 0px 0px 4px rgba(255, 255, 255, 0.2), inset 0px 10px 16px rgba(255, 255, 255, 0.32), inset 0px -10px 12px rgba(0, 0, 0, 0.24);
    }


    /* checkbox */

    .checklabel {
        display: inline-block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 20px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        &.image-check{
            padding-left: 0;
            margin-bottom: 0;
            font-size:0;
            .checkmark{
                top:12px;
                left:12px;
            }
            input {
                :checked ~ figure:before {
                    opacity:1;
                }
            }
        }

        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
            :checked ~ .checkmark {
                background-color: #38d00b;
                border: none;
            }
            :checked ~ .checkmark:after {
                display: block;
            }
        }
        .checkmark{

            :after {
                left: 7px;
                top: 3px;
                width: 4px;
                height: 9px;
                border: solid white;
                border-width: 0 3px 3px 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
            }
        }
        .checkmark {
            position: absolute;
            top: 4px;
            left: 0;
            height: 20px;
            width: 20px;
            border-radius: 4px;
            background-color:transparent;
            border: 2px solid #3B3D46;
            z-index: 2;
            :after {
                content: "";
                position: absolute;
                display: none;
            }
        }
    }

    /* react-select */
    .react-select{
        .react-select__control{
            background: #fff;
            border-radius: 10px;
            min-height: 48px;
            width: 100%;
            padding: 0 13px;
            border:1px solid #B1B1B1 !important;
            box-shadow:none;
            color: var(--text--color);
            .react-select__value-container{
                padding:0;
            }
            &.react-select__control--menu-is-open{
                .react-select__dropdown-indicator {
                    transform: rotate(180deg);
                    -webkit-transform:rotate(180deg);
                    -moz-transform:rotate(180deg);
                    -ms-transform:rotate(180deg);
                }
            }
        }
        .react-select__indicator-separator {
            display:none;
        }
        .react-select__indicator{
            padding:0;
            border-radius: 10px;
            svg{
                color: #D6D0DC;
            }
        }
       
        .react-select__single-value,.react-select__placeholder {
            font-weight: 400;
            font-size: 14px;
            line-height: 142%;
            color: #3B3D46;
        }
        .react-select__menu{
            background-color: #f5f5f5;
            box-shadow: 0px 2px 2px #00000029;
            -ms-box-shadow:  0px 2px 2px #00000029;
            -moz-box-shadow:  0px 2px 2px #00000029;
            -webkit-box-shadow:  0px 2px 2px #00000029;
            z-index: 1;
            .react-select__option {
                padding:11px 40px 11px 12px;
                font-weight: 400;
                font-size: 14px;
                line-height: 142%;
                color: #3B3D46;
                position:relative;
                background-color:#f5f5f5;
                cursor: pointer;
                &:before{
                    position:absolute;
                    content:'';
                    top:0;
                    bottom:0;
                    right: 20px;
                    margin:auto;
                    background-image: url(${toAbsoluteUrl(`/images/right.svg`)});
                    opacity:0;
                    width: 13px;
                    height: 10px;
                }
                &:hover{
                    background-color: rgba(56,208,11,1);
                    color: #ffffff;
                }
                &.react-select__option--is-selected{
                    background-color: rgba(56,208,11,1);
                    color: #ffffff;
                    &:before{
                        opacity:1;
                    }
                }
            }
            .react-select__menu-list{
                padding:0;
            }
        }
    }

    /* Modal */
    .react-responsive-modal-container{
      .react-responsive-modal-modal{
        max-width:350px;
        padding: 0;
        border-radius: 10px;
        .modal-body{
          padding:40px 30px 30px 30px;
          text-align: center;
          h2{
            color:var(--text--color);
            font-weight:600;
          }
        }
        .modal-btn{
          display:flex;
          li{
            width:50%;
            button{
              width:100%;
              border-radius: 0;
            }
          }
        }
      }
    }

    /* Pagination */
    .pagination{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        li{
            width:35px;
            height:35px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color:var(--grey--color);
            color:var(--black--color);
            margin-right:15px;
            cursor:pointer;
            border-radius: 12px;
            a{
                line-height: 35px;
            }
            &.disabled{
                background-color:var(--lightblack--color);
            }
            &.selected{
                background-color:var(--btn--color);
                color:var(--white--color);
            }
            &:last-child{
                margin-right:0;
            }
        }
    }

`;

export default Theme