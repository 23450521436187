import { doLogout } from "../actions/auth";
import store from '../store';
//To concate the path for the public folder
export const toAbsoluteUrl = pathname => (process.env.PUBLIC_URL + pathname)

const storeData = store?.getState();
export const setupAxios = (axios, store) => {
    const marketId = storeData?.farmermarket?.farmerMarket?.data?._id;
    const token = JSON.parse(localStorage.getItem("accessToken"))

    if (token) {
        axios.defaults.headers.common['authorization'] = token
    } else {
        axios.defaults.headers.common['authorization'] = null
    }

    axios.interceptors.response.use(null, (err) => {
        if (err.response) {
            if (err.response.status === 403) {
                store.dispatch(doLogout({ farmerMarketId: marketId }))

                return Promise.reject(err);
            }
            else if (err.response.status === 401) {
                // toast.error(err.response.data.message);
                console.log("hiii")
                setTimeout(() => {
                    const signinURL = `${window.location.origin}`;
                    window.location.replace(signinURL);
                    store.dispatch(doLogout({ farmerMarketId: marketId }));
                }, 1000);
            } else return Promise.reject(err);
        }
        else if (err.request) {
            return Promise.reject({
                response: {
                    data: {
                        message: "Something went wrong, Please try again later!!!"
                    }
                }
            });
        }
    });
}
