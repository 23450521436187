import React, { lazy } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import AuthGuard from '../components/auth';
const ResetPassword = lazy(() => import('../pages/ResetPassword'))
const Layout = lazy(() => import('../components/layout'))
const Login = lazy(() => import('../pages/Login'))
const Profile = lazy(() => import('../pages/Profile'))
const ForgotPassword = lazy(() => import('../pages/ForgotPassword'))
const ChangePassword = lazy(() => import('../pages/ChangePassword'))
const Reset_password_forgot = lazy(() => import('../pages/ResetPassword_forgot'))
const Home = lazy(() => import('../pages/Home'))

const Routing = () => {
    return (
        <Routes>

            <Route path="/" element={<Login />} />
            <Route path='/reset_password' element={<ResetPassword />} />
            <Route path='/forgot-password' element={<ForgotPassword />} />
            <Route path='/reset_password_forgot/:id' element={<Reset_password_forgot />} />
            <Route path="/" element={<AuthGuard><Layout /></AuthGuard>}>
                <Route path='/home' element={<Home />} />
                <Route path='/profile' element={<Profile />} />
                <Route path='/change-password' element={<ChangePassword />} />
            </Route>
            <Route path="*" element={<Navigate replace to="/" />} />
        </Routes>
    );
}

export default Routing;