import { createGlobalStyle } from "styled-components";

const Reset = createGlobalStyle`

* {
    margin:0px;
    padding:0px;
    border:none;
    outline:none;
    -webkit-box-sizing :border-box;
    -ms-box-sizing :border-box;
    -moz-box-sizing :border-box;
    box-sizing :border-box;
}

html {
    -webkit-text-size-adjust:none;
    -ms-text-size-adjust:none;
    -moz-text-size-adjust:none;
    text-size-adjust:none;
}

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a {
    text-decoration: none;
    &:hover,&:focus {
        text-decoration: none;
    }
}
button {
    outline: 0;
    background-color: transparent;
    cursor:pointer;
    &:hover, &:focus {
        outline: 0;
    }
}

h1, h2, h3, h4, h5, h6, strong, b {
    margin: 0;
    line-height: normal;
    letter-spacing: 1px;

}
p {
    margin: 0;
    line-height: normal;

}
ul,ol {
    list-style: none;
    margin: 0;
    padding: 0;
}
div,aside,figure,figcaption, label {
    margin: 0;
}
section,header,footer{
    display: inline-block;
    width: 100%;
}

.transition {
    -webkit-transition: all 0.4s ease-in-out;
    -ms-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
}

input,textarea,select{
     -webkit-appearance: none;
    -ms-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
header{
     -webkit-appearance: none;
    -ms-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
::-moz-selection {
    background: #42A44C;
    color: $white;
}
::selection {
    background: #42A44C;
    color: $white;
}

::-webkit-input-placeholder {text-align: left;color:var(--text--color);font-size:16px;font-weight:300;font-family: 'Poppins', sans-serif;}
:-moz-placeholder {text-align: left;color:var(--text--color);font-size:16px;font-weight:300;font-family: 'Poppins', sans-serif;}
:-ms-input-placeholder {text-align: left;color:var(--text--color);font-size:16px;font-weight:300;font-family: 'Poppins', sans-serif;}

` ;
export default Reset