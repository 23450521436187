import {
    API_CHANGEPASSWORD,
    API_FORGOTPASSWORD,
    API_LOGIN, API_LOGOUT, API_RESETPASSWORD, API_RESETPASSWORD_FORGET,
} from "../constants/api";
import {
    API,
    LOGIN_S,
    LOGIN_F,
    LOGOUT,
    RESET_PASSWORD_S,
    RESET_PASSWORD_F,
    FORGOT_PASSWORD_S,
    FORGOT_PASSWORD_F,
    CHANGE_PASSWORD_S,
    CHANGE_PASSWORD_F,
    RESET_PASSWORD_FS,
    RESET_PASSWORD_FF
} from "../constants/types";

export const doLogin = (data) => ({

    type: API,
    payload: {
        url: API_LOGIN,
        method: 'POST',
        data: data,
        success: (data) => ({
            type: LOGIN_S,
            payload: data
        }),
        error: (data) => ({
            type: LOGIN_F,
            payload: data
        })
    }
})


export const doChangePassword = (data) => ({
    type: API,
    payload: {
        url: API_CHANGEPASSWORD,
        method: 'POST',
        data: data,
        success: (data) => ({
            type: CHANGE_PASSWORD_S,
            payload: data
        }),
        error: (data) => ({
            type: CHANGE_PASSWORD_F,
            payload: data
        })
    }
})

export const doResetPassword = (data) => ({
    type: API,
    payload: {
        url: API_RESETPASSWORD,
        method: 'POST',
        data: data,
        success: (data) => ({
            type: RESET_PASSWORD_S,
            payload: data
        }),
        error: (data) => ({
            type: RESET_PASSWORD_F,
            payload: data
        })
    }
})

export const doForgotPassword = (data) => ({
    type: API,
    payload: {
        url: API_FORGOTPASSWORD,
        method: 'POST',
        data: data,
        success: (data) => ({
            type: FORGOT_PASSWORD_S,
            payload: data
        }),
        error: (data) => ({
            type: FORGOT_PASSWORD_F,
            payload: data
        })
    }
})

export const doResetForgotPassword = (data) => ({
    type: API,
    payload: {
        url: API_RESETPASSWORD_FORGET,
        method: 'POST',
        data: data,
        success: (data) => ({
            type: RESET_PASSWORD_FS,
            payload: data
        }),
        error: (data) => ({
            type: RESET_PASSWORD_FF,
            payload: data
        })
    }
})

export const doLogout = (id) => ({
    type: API,
    payload: {
        url: API_LOGOUT,
        method: 'POST',
        data: id,
        success: (data) => ({
            type: LOGOUT,
            payload: data
        }),
        error: (data) => ({
            type: LOGOUT,
            payload: {}
        }),
    }
})

export const dummyLogin = () => ({
    type: LOGIN_S,
    payload: {
        id: 1,
        name: 'John Doe',
        token: 'dummy-auth-token',
    }
})