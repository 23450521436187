import { createGlobalStyle } from "styled-components";

const Variable = createGlobalStyle`

:root {
// Color Variable
--text--color: #3B3D46;
--desc--color: #AFA8B5;
--btn--color: #38D00B;
--bullet--color: #B1B1B1;


--white--color: #ffffff;
--black--color: #000000;
--lightblack--color: #B1B1B1;
--grey--color: #EEEEEE;

--success--color: #089B55;
--danger--color: #D33520;
--warning--color: #E5B64B;
--info--color: #448FB8;
}
` ;

export default Variable