import { combineReducers } from 'redux';

import api from './api';
import auth from './auth';
import farmermarket from './farmermarket'

const rootReducer = combineReducers({
    api,
    auth,
    farmermarket
})

export default rootReducer;