import { ADD_EVENT_F, ADD_EVENT_S, ADD_EVENT_STATUS_F, ADD_EVENT_STATUS_S, ADD_FARMERMARKET_F, ADD_FARMERMARKET_S, ASSOCIATED_F, ASSOCIATED_S, DEASSOCIATED_F, DEASSOCIATED_S, FARMERMARKET_PROFILE_F, FARMERMARKET_PROFILE_S, GET_COUNTIES_F, GET_COUNTIES_S, GET_EVENT_F, GET_EVENT_S, GET_FARMERLIST_F, GET_FARMERLIST_S, GET_STATE_F, GET_STATE_S, REQUEST_F, REQUEST_S, SUSPEND_F, SUSPEND_S, UPDATE_EVENT_F, UPDATE_EVENT_S } from "../constants/types";

const initialState = {
    isoggedIn: false,
    farmerMarket: {},
    farmerState: {},
    farmerConties: {},
    farmerList: {},
    associtedMarket: {},
    request: {},
    farmerMarketDetail: {},
    deassocitedMarket: {},
    suspendMarketDetail: {},
    events: {},
    eventDetail: {},
    eventList: {}
}

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case FARMERMARKET_PROFILE_S:
            return { ...state, farmerMarket: action.payload, isoggedIn: true, };
        case FARMERMARKET_PROFILE_F:
        case GET_STATE_S:
            return { ...state, farmerState: action.payload, isoggedIn: true };
        case GET_STATE_F:
        case GET_COUNTIES_S:
            return { ...state, farmerConties: action.payload, isoggedIn: true };
        case GET_COUNTIES_F:
        case GET_FARMERLIST_S:
            return { ...state, farmerList: action.payload, isoggedIn: true };
        case GET_FARMERLIST_F:
        case GET_EVENT_S:
            return { ...state, eventDetail: action.payload, };
        case GET_EVENT_F:
        case ASSOCIATED_S:
            return { ...state, associtedMarket: action.payload, isoggedIn: true };
        case ASSOCIATED_F:
        case DEASSOCIATED_S:
            return { ...state, deassocitedMarket: action.payload, isoggedIn: true };
        case DEASSOCIATED_F:
        case REQUEST_S:
            return { ...state, request: action.payload, isoggedIn: true };
        case REQUEST_F:
        case ADD_FARMERMARKET_S:
            return { ...state, farmerMarketDetail: action.payload, };
        case ADD_FARMERMARKET_F:
        // case ADD_EVENT_S:
        //     return { ...state, events: action.payload, isoggedIn: true };
        // case ADD_EVENT_F:
        case UPDATE_EVENT_S:
            return { ...state, events: action.payload, isoggedIn: true };
        case UPDATE_EVENT_F:
        case ADD_EVENT_STATUS_S:
            return { ...state, eventList: action.payload };
        case ADD_EVENT_STATUS_F:
        case SUSPEND_S:
            return { ...state, suspendMarketDetail: action.payload, isoggedIn: true };
        case SUSPEND_F:
        default:
            return state;
    }
}

export default authReducer;