import {
    LOGIN_S,
    LOGIN_F,
    LOGOUT,
    RESET_PASSWORD_S,
    RESET_PASSWORD_F,
    FORGOT_PASSWORD_S,
    FORGOT_PASSWORD_F,
    CHANGE_PASSWORD_S,
    CHANGE_PASSWORD_F,
    RESET_PASSWORD_FS,
    RESET_PASSWORD_FF,
} from '../constants/types';


const initialState = {
    message: "",
    error: null,
    user: {},
    isLoggedIn: true,
    passwordChange: false,
    isProfileUpdate: false,
}

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_S:
            // set access token for rest of the api
            localStorage.setItem('authToken', JSON.stringify(action.payload.data.accessToken));
            localStorage.setItem('userId', JSON.stringify(action.payload.data._id));
            localStorage.setItem('user', JSON.stringify(action.payload.data));
            return { ...state, user: action.payload, isLoggedIn: true, };
        case LOGIN_F:
        case RESET_PASSWORD_S:
            return { ...state, user: action.payload };
        case RESET_PASSWORD_F:
        case CHANGE_PASSWORD_S:
            return { ...state, user: action.payload };
        case CHANGE_PASSWORD_F:
        case FORGOT_PASSWORD_S:
            return { ...state, user: action.payload };
        case FORGOT_PASSWORD_F:
        case RESET_PASSWORD_FS:
            return { ...state, user: action.payload };
        case RESET_PASSWORD_FF:
        case LOGOUT:
            localStorage.removeItem('user');
            localStorage.removeItem('authToken');
            localStorage.removeItem('userId');

            return { isLoggedIn: false, initialState };
        default:
            return state;
    }
}

export default authReducer;