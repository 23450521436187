export const {
    REACT_APP_API_BASE: API_BASE
} = process.env


//farmermarket login
export const API_LOGIN = 'auth/farmerMarketLogin'
export const API_LOGOUT = 'api/marketLogout'

//farmermarket reset password
export const API_CHANGEPASSWORD = 'auth/farmerMarketChangePassword'

export const API_RESETPASSWORD = 'auth/farmerMarketResetPassword'

export const API_RESETPASSWORD_FORGET = 'auth/resetPassword'

//forget password
export const API_FORGOTPASSWORD = 'auth/farmerMarketForgotPassword'

//get farmermarket profile details
export const API_FARMER_MARKET_PROFILE = 'api/getFarmerMarketProfile'

//get states of farmer market
export const API_GET_STATE = 'auth/getState'

//get counties of farmer market
export const API_GET_COUNTIES = 'auth/getCounties'

//get list of associated farmer
export const API_GET_LISTOFFARMERS = 'api/listOfFarmers'

//associated farmer
export const API_ASSOCIATEDMARKET = 'api/associateMarket'

//approve/reject of farmer market
export const API_REQUEST_FARMER = 'api/farmerVerification'

//deassociate farmer
export const API_DEASSOCIATEMARKET = `api/deAssociateFarmer`

//suspend market
export const API_SUSPENDMARKET = 'api/suspendFarmer'

//add farmer detail
export const API_MARKET_DETAIL = 'api/updateFarmerMarket'

//add event
export const API_ADD_EVENT = '/api/addEvent'

//get event detail
export const API_GET_EVENT = '/api/getEventDetailsForMarket'

//add event status
export const API_EVENT_STATUS = '/api/suspendEvent'

//update event 
export const API_UPDATE_EVENT = '/api/updateEvent'